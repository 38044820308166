<script>
import Swal from 'sweetalert2'

import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);
        const doesLoadingOpen = ref(false)

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
          doesLoadingOpen
        };
    },
    page: {
    title: "users",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Managers",
      items: [
        {
          text: "Définition",
        },
        {
          text: "Managers",
          active: true,
        },
      ],
      last_name: "",
      first_name: "",
      email: "",
      gender: "",
      password: "",
      phone: "",
      country_id: "",
      city: "",
      page: 1,
      perPage: 25,
      users: [],
      countries: [],
      errors: [],
      status: 201,
      loading: false,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('users/roles/MANAGER'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          return response.json()
      })
      .then(data => {
        
        this.users = data
        this.doesLoadingOpen = false
      })
    },
    loadcountries() {
      fetch(Helper.route('countries'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          return response.json()
      })
      .then(data => {
        console.log("countries ",data);
        this.countries = data
      })
    },
    addItem() {
      this.loading = true
      this.errors = []
      let body = {
        "first_name": this.first_name,
        "last_name": this.last_name,
        "gender": this.gender == "true",
        "password": this.password,
        "email": this.email,
        "phone": this.phone,
        "city": this.city,
        "country": {
          "id": this.country_id
        },
        "roles": [
          "MANAGER"
        ],
        "abilities": [
          "string"
        ]
      }
      console.log(body);
      fetch(Helper.route('users/manager/create'), Helper.requestOptionsJSON("POST", body))
      .then(response => {
          //Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        
        this.loading = false
        if (this.status == 201) {
          this.doesAddOpen = false
          this.users.push(data) 
          Swal.fire("Manager ajouter !", "Le manager a été enregistré avec succès.", "success");
        } else {
          Swal.fire("Ereur !", data.message, "error");
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    show(index) {
        return (index < this.page * this.perPage && (index + 1) > (this.page - 1) * this.perPage)
    },
    next() {
        if ((this.page * this.perPage) < this.users.length) {
            this.page++
        }
    },
    previous() {
        if (this.page >= 2) {
            this.page--
        }
    },
  },
  mounted() {
    this.loadList()
    this.loadcountries()
    this.doesLoadingOpen = false
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="hstack mb-3">
        <b-button variant="primary" @click="doesAddOpen = true" class="ms-auto">
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>
            Ajouter un nouveau
        </b-button>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des Managers</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>Managers</code>.
            </p>

            <div class="row">
              <div class="col-2">
                <select v-model="perPage" class="form-control text-center">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>

              <div class="ms-auto col-2 ps-5 hstack">
                <i class="bx bx-left-arrow-alt p-2 fs-5" type="button" @click="previous"></i>
                
                <i class="bx bx-right-arrow-alt fs-5 p-2 ms-3" type="button" @click="next"></i>
              </div>
            </div>

            <div class="table-responsive mt-4">
              <table class="table align-middle mb-0">
                <thead>
                  <tr class="bg-light">
                    <th>#</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Sexe</th>
                    <th>Email</th>
                    <th>Téléphone</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in users" v-show="show(index)" :key="user.id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ user.last_name }}</td>
                    <td>{{ user.first_name }}</td>
                    <td>{{ user.gender == true ? "Masculin" : "Féminin" }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.phone }}</td>
                    <td class="text-center">
                      <router-link :to="'/managers/'+user.id">
                        <b-button class="btn-sm ms-2 rounded-pill px-2" variant="primary">
                            Visiter le compte
                        </b-button>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            
          </div>
        </div>
      </div>
    </div>


    <!-- LOADING MODAL -->
    <b-modal v-model="doesLoadingOpen" id="modal-center" centered size="md" title="Chargement..."
      title-class="font-18" hide-footer>
      <div class="text-center">
        <div class="spinner-border text-primary-" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </b-modal>

    
    <!-- ADD MODAL -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        centered
        size="lg"
        title="Ajouter un manager"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItem()">
          <div class="row">
            <div class="col-md-6 mb-3">
                <label for="">Prénom</label>
                <input required v-model="first_name" class="form-control">
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Nom</label>
                <input required v-model="last_name" class="form-control">
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Sexe</label>
                <select required v-model="gender" class="form-control">
                  <option value=""></option>
                  <option value="true">Masculin</option>
                  <option value="false">Féminin</option>
                </select>
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Mot de passe</label>
                <input type="password" required v-model="password" class="form-control">
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Email</label>
                <input type="email" required v-model="email" class="form-control">
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Téléphone</label>
                <input type="tel" required v-model="phone" class="form-control">
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Pays</label>
                <select required v-model="country_id" class="form-control">
                  <option value=""></option>
                  <option v-for="country in countries" :key="country.id" :value="country.id" v-text="country.name"></option>
                </select>
            </div>
            <div class="col-md-6 mb-3">
                <label for="">Ville</label>
                <input type="text" required v-model="city" class="form-control">
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>

  </Layout>
</template>
